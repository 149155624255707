import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import datumAPI from '^/api/datum';

import PerformanceCard from '^/components/performance-card';
import { ProductModal } from '^/components/modals';
import { FiltersForm, SkinParamsForm } from '^/components/forms';

import ProductRecommendation from '../product-recommendation';

function IngredientMlDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [productRecommendations, setProductRecommendations] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();

  const [skinProfile, setSkinProfile] = useState({
    tone: 0,
    milieu: 0,
    hydration: 0,
    smoothness: 0,
    shineControl: 0,
    dermalFibers: 0,
    oxygenSaturation: 0,
  });

  const [filterParams, setFilterParams] = useState({
    priority: '',
    exclude: '',
  });

  function applyResponse({ data }) {
    setIsLoading(false);
    setProductRecommendations(data);
  }

  function loadProducts(skinProfile, filters) {
    setIsLoading(true);
    datumAPI.getProductRecommendations({ skinProfile, filters }).then(applyResponse);
  }

  useEffect(() => {
    loadProducts(skinProfile, filterParams);
    // eslint-disable-next-line
  }, [skinProfile, filterParams]);

  return (
    <Container fluid>
      <Row className="mb-4">
        <Col>
          <SkinParamsForm skinProfile={skinProfile} onChange={setSkinProfile} />
        </Col>

        <div className="w-100"></div>

        <Col>
          <FiltersForm onChange={setFilterParams} filterParams={filterParams} />
        </Col>
      </Row>

      <Row>
        <Col>
          <h3 className="border-bottom mb-3">Products</h3>
        </Col>
      </Row>

      {isLoading ? (
        <p className="text-center mt-5">Loading...</p>
      ) : (
        productRecommendations.map((recommendation) => (
          <Row key={recommendation.product.uid} className="mx-n1">
            <Col md={8} className="mb-5 px-1">
              <ProductRecommendation
                className="h-100"
                recommendation={recommendation}
                onProductClick={(product) =>
                  setSelectedProduct({
                    ...product,
                    performanceVector: recommendation.performanceVector,
                  })
                }
              />
            </Col>

            <Col className="mb-5 px-1">
              <PerformanceCard
                className="h-100"
                performanceVector={recommendation.performanceVector}
              />
            </Col>
          </Row>
        ))
      )}

      {!!selectedProduct && (
        <ProductModal product={selectedProduct} onHide={() => setSelectedProduct(null)} />
      )}
    </Container>
  );
}

export default IngredientMlDashboard;
