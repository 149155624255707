import React, { memo, Fragment } from 'react';
import { Form, InputGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { Typeahead, Highlighter, Token } from 'react-bootstrap-typeahead';
import { CheckBox, Square } from 'react-bootstrap-icons';

import { TAG_TYPES, TAG_TYPES_DICTIONARY } from './constants';

const TagTypes = ({ tags, tagOptions, tagJoinType, setTagJoinType, setTags }) => {
  const onChangeTags = (tags) => {
    const untagged = tags.find(({ name }) => name === '--UNTAGGED--');

    untagged ? setTags([untagged]) : setTags(tags);
  };

  return (
    <Form.Group>
      <InputGroup>
        <Typeahead
          id="tags"
          options={tagOptions}
          multiple
          labelKey="name"
          selected={tags}
          onChange={onChangeTags}
          placeholder="Tags"
          renderToken={(option, props, index) => {
            return (
              <Token key={index} onRemove={props.onRemove} option={option}>
                {option.name} <small>({option.count.toLocaleString()})</small>
              </Token>
            );
          }}
          selectHintOnEnter
          highlightOnlyResult
          renderMenuItemChildren={(option, props) => {
            return (
              <Fragment key={option.name}>
                <Highlighter search={props.text}>{option.name}</Highlighter>

                <small> ({option.count.toLocaleString()})</small>
              </Fragment>
            );
          }}
        />

        <DropdownButton
          as={InputGroup.Append}
          title={TAG_TYPES_DICTIONARY[tagJoinType]}
          variant="outline-secondary"
        >
          {TAG_TYPES.map(({ type, isIncludedAll, isWithSomething }) => {
            const prefixText = isIncludedAll ? 'all' : 'any';
            const middleText = isWithSomething ? 'With' : 'Without';
            const separatorText = isIncludedAll ? 'AND' : 'OR';

            return (
              <Dropdown.Item
                key={type}
                onClick={() => setTagJoinType(type)}
                disabled={tagJoinType === type}
              >
                {tagJoinType === type ? <CheckBox /> : <Square />}

                <b style={{ paddingLeft: '5px', paddingRight: '5px' }}>{middleText}</b>

                {tags.length === 0
                  ? prefixText
                  : tags
                      .flatMap(({ name }) => [
                        <b key={`a-${name}`}> {separatorText} </b>,
                        <span key={`b-${name}`}>"{name}"</span>,
                      ])
                      .slice(1)}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
      </InputGroup>
    </Form.Group>
  );
};

export default memo(TagTypes);
